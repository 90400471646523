import gql from 'graphql-tag';
// import {filter} from 'graphql-anywhere';

// Query
export const GET_PEOPLE = gql`
    query GET_PEOPLE($doc: String!) {
        people(doc: $doc) {
            Result {
                BasicData {
                    Name
                    TaxIdNumber
                    TaxIdCountry
                    TaxIdStatus
                    TaxIdStatusDate
                    Gender
                    BirthDate
                    Age
                    ZodiacSign
                    BirthCountry
                    MotherName
                    FatherName
                    TaxIdStatus
                    HasObitIndication
                    ObitIndicationYear
                    ObitIndicationOrigin
                    CreationDate
                    LastUpdateDate
                    AlternativeIdNumbers {
                        SocialSecurityNumber
                        RG
                        CNH
                    }
                }
            }
        }
    }
    # fragment people on People {
        
    # }
`;

// Mutations
export const STORE_PEOPLE  = gql`
    mutation storePeople(
            $email: String!
            $name: String!
            $cpf: String
            $phone: String
            $group: String!
            $ID_document: String
            $status: StatusEnum!
            $payment_type: String
        ) {
        	storeUser(
            user: {
                email: $email
                name: $name
                cpf: $cpf
                phone: $phone
                group: $group
                ID_document: $ID_document
                status: $status
                payment_type: $payment_type
            }
        ) {
            id
            buyer_id
            customer_id
        }
    }
`;